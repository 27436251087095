<template>
  <div>
    <div class="pc_aggregate">
      <div class="banner-box">
        <div class="banner-boxs">
          <div class="banner-name">医院管理整体解决方案</div>
          <div class="banner-bnt" @click="openFun(true)">立即咨询</div>
        </div>
      </div>
      <div class="content-box" v-scroll-reveal>
        <div class="aggregate-box">
          <div
            class="aggregate-item"
            v-for="(item, index) in list"
            :key="index"
            @click="toMore(item)"
            @mouseover="handleMouseOver" 
            @mouseout="hideOverlay"
          >
            <div>
              <div class="aggregate-img">
                <img :src="item.img" alt="" />
                <div class="overlay" v-show="isOverlayVisible">
                    <button class="btnBox">查看详情</button>
                </div>
              </div>
              <div class="aggregate-name">{{ item.name }}</div>
              <div class="aggregate-introduce">{{ item.introduce }}</div>
            </div>
            <!-- <div class="aggregate-more">了解更多</div> -->
          </div>
        </div>
      </div>
      <relation :open="open" @openFun="openFun"/>
    </div>
    <mobileAggregate
      class="mobile_aggregate"
      :list="list"
      @toMore="toMore"
    />
  </div>
  
</template>

<script>
import relation from "../components/relation";
import mobileAggregate from '@/mobileViews/aggregate.vue'
export default {
  components: { relation, mobileAggregate },
  data() {
    return {
      open: false,
      list: [
        {
          img: require("../assets/img/aggregateImg1.png"),
          name: "现代医院绩效管理体系建设",
          introduce:
            "以预算为基础，工作量（DRGs&DIP）为依据，以绩效管理为抓手-促进医院“提质增效”",
          more: "/modern",
        },
        {
          img: require("../assets/img/aggregateImg2.png"),
          name: "组织结构治理与定岗定编规划",
          introduce: "科学构建人力资源战略规划，搭建医疗人才梯队",
          more: "/government",
        },
        {
          img: require("../assets/img/aggregateImg3.png"),
          name: "医院战略发展规划",
          introduce: "五定一做一分析，精准战略定位和发展方向",
          more: "/development",
        },
        {
          img: require("../assets/img/aggregateImg4.png"),
          name: "易策EHP医院绩效软件系统",
          introduce: "聚焦目标 科学管理“数”说策略",
          more: "/ehp",
        },
        {
          img: require("../assets/img/aggregateImg5.png"),
          name: "医院集团化、县域医共体建设规划",
          introduce: "",
          more: "/plan",
        },
        {
          img: require("../assets/img/aggregateImg6.png"),
          name: "等级评审，推动医院跨越式发展医院等级评审辅导咨询项目",
          introduce: "",
          more: "/review",
        },
      ],
      isOverlayVisible: true
    };
  },
  methods: {
    openFun(bo){
      this.open = bo
    },
    toMore(item) {
      this.$router.push({ path: item.more });
    },
    handleMouseOver(){
      this.isOverlayVisible = true
    },
    hideOverlay(){
      this.isOverlayVisible = false
    }
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  width: 100%;
  height: 400px;
  background-image: url("../assets/img/case.png");
  background-repeat: no-repeat;
  // background-position: 0 -181px;
  background-size: 100%;
  .banner-boxs {
    width: 1200px;
    margin: 0 auto;

    .banner-name {
      font-size: 52px;
      font-weight: 400;
      color: #fff;
      padding-top: 89px;
    }

    .banner-bnt {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #2d43f2;
      border-radius: 50px;
      color: #fff;
      font-size: 16px;
      margin-top: 48px;
      cursor: pointer;
      transition: all 0.2s;
      &:hover {
        background: #4273ff;
      }
    }
  }
}
.content-box {
  width: 1200px;
  margin: 30px auto 60px;

  .aggregate-box {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .aggregate-item {
      width: 378px;
      margin-right: 30px;
      margin-top: 30px;
      border: 1px solid #e1e1e1;
      cursor: pointer;
      transition: all 0.3s;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &:hover {
        transform: scale(1.02);
        box-shadow: 0px 3px 14px 1px rgba(99, 99, 99, 0.16);
      }

      .aggregate-img {
        width: 100%;
        height: 240px;
        overflow: hidden;
        position: relative;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          // position: absolute;
          // right: -100px;
          // top: 0;
        }
      }

      .aggregate-name {
        color: #333;
        margin: 11px 21px;
        font-size: 18px;
        font-weight: bold;
      }

      .aggregate-introduce {
        width: 322px;
        height: 41px;
        font-size: 14px;
        font-weight: 400;
        color: #949494;
        margin: 11px 21px;
      }

      .aggregate-more {
        font-size: 12px;
        font-weight: 400;
        color: #1278cb;
        margin: 18px 21px;
        position: relative;
        &::after {
          position: absolute;
          top: calc(50% - 4px);
          margin-left: 3px;
          content: "";
          width: 5px;
          height: 9px;
          background-image: url("../assets/img/agg_more.png");
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 380px;
        height: 240px;
        background-color: rgba(0, 0, 0, 0.5); /* 遮罩层的背景颜色和透明度 */
        display: none;
        line-height: 240px;
        z-index: 999;

        .btnBox{
            width: 120px;
            height: 40px;
            background: #3388FF;
            color: #fff;
            border-radius: 20px 20px 20px 20px;
            opacity: 1;
            border: 0px;
            translate: 130px;
            font-size: 16px;
            cursor: pointer;
        }
      }      
    }

    .aggregate-item:hover .overlay {
          display: block; /* 鼠标移入图片时显示遮罩层 */
      }
  }
}

.mobile_aggregate {
  display: none;
}
@media screen and (max-width: 650px) {
  .mobile_aggregate {
    display: block;
    background-color: #fff;
  }
  .pc_aggregate {
    display: none;
  }
}
</style>
