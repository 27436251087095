<template>
  <div>
    <div class="m_banner">
      <div class="m_banner_text">解决方案</div>
      <div class="m_banner_i"></div>
      <div class="m_banner_text2">为医院提供管理整体解决方案</div>
    </div>
    <div class="aggregate_m_box">
      <div class="aggregate_m_item" v-for="(item, index) in list" :key="index" @click="toMore(item)">
        <img class="aggregate_m_img" :src="item.img" alt="" />
        <div class="aggregate_m_name">{{ item.name }}</div>
        <div class="aggregate_m_introduce">{{ item.introduce }}</div>
      </div>
    </div>
  </div>
  
</template>

<script>
  export default {
    props: ['list'],
    data() {
      return {
        
      }
    },
    methods: {
      toMore(item) {
        this.$emit('toMore', item)
      }
    }
  };
</script>

<style lang="scss" scoped>
  .m_banner {
    width: 100%;
    height: 515px;
    background-image: url("../assets/img/mobile_bg5.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .m_banner_text {
      font-size: 32px;
      color: #fff;
      text-align: center;
      padding-top: 132px;
    }
    .m_banner_i {
      width: 45px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 16px;
    }
    .m_banner_text2 {
      font-size: 40px;
      color: #fff;
      text-align: center;
    }
  }
  .aggregate_m_box {
    padding: 38px;
    .aggregate_m_item {
      background: #FFFFFF;
      box-shadow: 0px 2px 10px 1px rgba(86,107,139,0.16);
      border-radius: 8px;
      margin-bottom: 50px;
      overflow: hidden;
      .aggregate_m_img {
        width: 674px;
        height: 278px;
      }
      .aggregate_m_name {
        font-size: 28px;
        font-weight: bold;
        color: #333333;
        text-align: center;
        padding: 25px 30px;
      }
      .aggregate_m_introduce {
        font-size: 26px;
        font-weight: 400;
        color: #333333;
        padding: 0 30px 30px 30px;
        text-align: center;
      }
    }
  }
</style>
